import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getStaffMedicos(context, dados) {
        if (((!dados.pagina) || (!dados.quantidadePorPagina)) && (!dados.zero)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicosDoDia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/listarMedicoDoDia", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicosSimples() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("staffMedicos/access/listarSimples").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicosBox(context, filtros) {
        filtros.componente = "SearchBoxStaffMedico";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/obter", { ID_MEDICO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoSimples(context, idMedico) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/obterSimples", { ID_MEDICO: idMedico }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoWeb(context, idMedico) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("medicos/access/obterMedicoWeb", { ID_MEDICO: idMedico }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoUsuario() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("staffMedicos/access/obterMedicoUsuario").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarMedicosUsuario() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("staffMedicos/access/listarMedicosUsuario").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoEspecialidades(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/listarEspecialidades", {
                    ID_MEDICO: dados.id,
                    ID_UNIDADE: dados.idUnidade
                }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoEspecialidadesMedicas(context, idMedico) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/listarEspecialidadesMedicas", {ID_MEDICO: idMedico}).then(({ data }) => {resolve(data);
                });
            }
        });
    },
    deleteStaffMedico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/excluir", { ID_MEDICO: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postStaffMedico(context, staffMedico) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/salvar", staffMedico).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getModelosLaudos(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("staffMedicos/access/modelosLaudos", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};