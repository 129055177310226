import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getConvenios(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getConvenioBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/listaBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getConvenioMestreBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/listaConvenioBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getConvenio(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/obter", {ID_CONVENIO_MESTRE: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getConvenioFoto(context, id) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/obterFoto", {ID_CONVENIO: id}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPlanos(context, idConvenioMestre) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/listarPlanos", {ID_CONVENIO_MESTRE: idConvenioMestre}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPlanosTodas() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("convenios/access/listaLocal").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    getPlanoSimples(context, idConvenio) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/obterPlanoSimples", {ID_CONVENIO: idConvenio}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    getPlanoPorDescricao(context, descricao) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/obterPlanoPorDescricao", {DESCRICAO: descricao}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getConvenioInfoAdic(context, idConvenio) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/obterConvenioInfoAdic", {ID_CONVENIO_MESTRE: idConvenio}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },    
    deleteConvenio(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/excluir", {ID_CONVENIO_MESTRE: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postConvenio(context, convenio) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("convenios/access/salvar", convenio).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};