import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getLocaisTodas(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteLocal(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/excluir", {ID_LOCAL: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLocais() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("locaisAtendimento/access/listarSimples").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLocaisPorUnidade(context, idUnidade) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/listarPorUnidade", {ID_UNIDADE: idUnidade}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLocalSimples(context, idLocal) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/obterSimples", {ID_LOCAL: idLocal}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLocalAtendimento(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/obterAtendimento", { ID_LOCAL: dados.id, componente: dados.componente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postLocal(context, local) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/salvar", local).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getLocaisBox(context, filtros) {
        filtros.componente = "SearchBoxLocalAtendimento";
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("locaisAtendimento/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};