import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getProcedimentos(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        dados.tipo = 'C';
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getProcedimentosBox(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBoxTipoC", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getProcedimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/obter", {ID_PRODUTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteProcedimento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/excluir", {ID_PRODUTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postProcedimento(context, dados) {
        if(dados.TIPO_SUS != 'BPA-C'){
            dados.SUS_BPAC_SEM_IDADE = '0';
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/salvar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};